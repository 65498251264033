const sendData = (onSuccess, onFail, form) => {
  const formData = form.serialize();
  $.ajax({
    type: 'POST',
    url: '/php/smartAjax.php',
    data: formData,
    success: (data) => onSuccess(data, form),
    error: (data) => onFail(data),
  })
};

const onSuccess = (data, form) => {
  const successModal = $("#modalthankyou");
  const currentModal = $(".modal");
  form.trigger('reset');
  if(currentModal && currentModal.hasClass('open')) {
    currentModal.removeClass('open');
  }
  setTimeout(function(){
    successModal.addClass('open');
  },300);
  setTimeout(function(){
    successModal.removeClass('open');
  },3700);
};

const onFail = (data) => {
  console.log(data);
  alert("Возникла ошибка!");
};

document.addEventListener('DOMContentLoaded', () => {
  const form1 = $('#form-1');
  const form2 = $('#form-2');
  const form3 = $('#form-3');
  const form4 = $('#form-4');
  const form5 = $('#form-5');
  const form6 = $('#form-6');
  const form7 = $('#form-7');
  const form8 = $('#form-8');

  form1.submit(function(){
    sendData(
      onSuccess,
      onFail,
      form1
    )
  });
  form2.submit(function(){
    sendData(
      onSuccess,
      onFail,
      form2
    )
  });
  form3.submit(function(){
    sendData(
      onSuccess,
      onFail,
      form3
    )
  });
  form4.submit(function(){
    sendData(
      onSuccess,
      onFail,
      form4
    )
  });
  form5.submit(function(){
    sendData(
      onSuccess,
      onFail,
      form5
    )
  });
  form6.submit(function(){
    sendData(
      onSuccess,
      onFail,
      form6
    )
  });
  form7.submit(function(){
    sendData(
      onSuccess,
      onFail,
      form7
    )
  });
  form8.submit(function(){
    sendData(
      onSuccess,
      onFail,
      form8
    )
  });
});
